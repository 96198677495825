<template>
    <div v-if="show">
        <div class="card">
            <div class="card-header card-header-flex pb-2">
                <div class="w-100 mt-2">
                    <div class="row">
                        <div class="col-8">
                            <h5 class="mt-3 ml-0 mr-3 mb-2">
                                <strong>
                                    <template v-if="operation === null">
                                        {{ $t('title.locationCodes') }}
                                        <span v-if="pagination.total">({{ pagination.total }})</span>
                                    </template>
                                    <template v-else>{{ $t(operationTitle) }}</template>
                                </strong>
                            </h5>
                        </div>
                        <div class="col-4 text-right">
                            <div v-if="operation === null">
                                <div class="mt-3">
                                    <!-- Using components -->
                                    <b-input-group class="mt-3">
                                        <b-form-input type="search" class="form-control form-control-sm"
                                                      :placeholder="$t('input.whatAreYouLookingFor')"
                                                      v-on:keyup.enter="handleSearch" v-model="search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button size="sm" :title="$t('button.title.filterRecords')"
                                                      variant="outline-info"
                                                      @click="filters.visible = !filters.visible" v-b-tooltip.hover
                                                      v-if="$global.hasPermission('locationcodesview')">
                                                <i class="fa fa-filter"></i>
                                            </b-button>
                                            <b-button size="sm" :title="$t('button.title.resetList')"
                                                      variant="outline-info"
                                                      @click="handleResetFilterClick()" v-b-tooltip.hover>
                                                <i class="fa fa-refresh"></i>
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </div>
                            </div>
                            <div v-else>
                                <b-button variant="warning" size="sm" class="mt-3"
                                          @click="handleOperationClose()"
                                          v-b-tooltip.hover :title="$t('button.title.cancel')">
                                    <i class="fa fa-arrow-left"></i> {{ $t('button.cancel') }}
                                </b-button>
                            </div>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.w-100 -->
            </div><!-- /.card-header -->
            <div class="card-body">
                <div class="locationcodes-table">
                    <b-table hover responsive
                             ref="table"
                             :busy="listingLoading"
                             :items="dataSource"
                             :fields="columns"
                             :sort-by.sync="sortField"
                             @sort-changed="handleSortChange">
                        <template v-slot:cell(object_type)="{item}">
                            <span v-if="item.object_type === 'suppliers'">{{ $t('column.suppliers') }}</span>
                            <span v-if="item.object_type === 'supplier_locations'">{{ $t('column.supplier') }} {{ $t('column.locations') }}</span>
                            <span v-if="item.object_type === 'dealers'">{{ $t('column.dealers') }}</span>
                            <span v-if="item.object_type === 'dealer_locations'">{{
                                    $t('column.dealer')
                                }} {{ $t('column.locations') }}</span>
                            <span v-if="item.object_type === 'manufacturers'">{{ $t('column.manufacturers') }}</span>
                            <span v-if="item.object_type === 'manufacturer_locations'">{{ $t('column.manufacturer') }} {{ $t('column.locations') }}</span>
                            <span v-if="item.object_type === 'locations'">{{ $t('column.locations') }}</span>
                        </template>
                        <template v-slot:cell(location_id)="{item}">
                            <span v-b-tooltip.hover>{{ (item.location || {}).label }}</span>
                        </template>
                        <template v-slot:cell(created_at)="{item}">
                            {{ $global.utcDateToLocalDate(item.created_at) }}
                        </template>
                    </b-table><!-- /.b-table -->

                    <div class="clearfix">
                        <div class="float-left ">
                            <b-form-select v-model="pagination.perPage" :options="[5, 10, 50, 100, 1000]"
                                           size="sm"></b-form-select>
                        </div>
                        <div class="float-right">
                            <b-pagination
                                v-model="pagination.current"
                                :total-rows="pagination.total"
                                :per-page="pagination.perPage"
                                :first-text="$t('paginations.first')"
                                :prev-text="$t('paginations.prev')"
                                :next-text="$t('paginations.next')"
                                :last-text="$t('paginations.last')"
                            ></b-pagination>
                        </div><!-- /.pull-right -->
                    </div><!-- /.clearfix -->
                </div><!-- /.locationCodes-table -->
                <div class="filter-container">
                    <a-drawer
                        placement="left"
                        :width="'360px'"
                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                        :closable="false"
                        @close="filters.visible = !filters.visible"
                        :visible="!operation && filters.visible"
                        :zIndex="10"
                        :title="$t('title.advanceFilters')"
                    >
                        <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
                            <b-row>
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.suppliers')"
                                        label-for="toDate">
                                        <treeselect
                                            :multiple="false"
                                            :options="dropdowns.suppliers"
                                            placeholder=""
                                            v-model="filters.suppliers"
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.manufacturers')"
                                        label-for="manufactures">
                                        <treeselect
                                            :multiple="false"
                                            :options="dropdowns.manufacturers"
                                            placeholder=""
                                            v-model="filters.manufacturers"
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.clients')"
                                        label-for="clients">
                                        <treeselect
                                            :multiple="false"
                                            :options="dropdowns.clients"
                                            placeholder=""
                                            v-model="filters.clients"
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.dealers')"
                                        label-for="dealers">
                                        <treeselect
                                            :multiple="false"
                                            :options="dropdowns.dealers"
                                            placeholder=""
                                            v-model="filters.dealers"
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.fromAddedDate')"
                                        label-for="fromDate">
                                        <b-form-datepicker placeholder="" id="fromDate" v-model="filters.from_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.toAddedDate')"
                                        label-for="toDate">
                                        <b-form-datepicker placeholder="" id="toDate" v-model="filters.to_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                            </b-row>
                            <div class="drawer-footer">
                                <b-button size='sm' variant="info" @click="filters.visible = !filters.visible"
                                          class="mr-2" :title="$t('button.title.closePanel')" v-b-tooltip.hover>
                                    {{ $t('button.close') }}
                                </b-button>
                                <b-button size='sm' variant="warning" @click="handleResetFilterClick" class="mr-2"
                                          :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
                                    {{ $t('button.reset') }}
                                </b-button>
                                <b-button size='sm' variant="primary" button="submit" type="filled"
                                          :title="$t('button.title.filterRecords')" v-b-tooltip.hover>
                                    {{ $t('button.apply') }}
                                </b-button>
                            </div><!-- /.drawer-footer -->
                        </form>
                    </a-drawer>
                </div><!-- /.filter-container -->
            </div><!-- /.card-body-->
        </div><!-- /.card -->
    </div>
</template>
<script>
import ListingMixin from '../../util/ListingMixin'
import {mapState} from 'vuex'
import Datepicker from 'vuejs-datepicker'
import Treeselect from '@riophae/vue-treeselect'
import {request} from "../../util/Request";

const FILTER_STATE = {
    visible: false,
    from_date: null,
    to_date: null,
    suppliers: null,
    clients: null,
    manufacturers: null,
    dealers: null,
};

const COLUMN_DEFINITION = (self) => [
    {
        label: self.$t('column.objectType'),
        key: 'object_type',
        sortable: true,
        sortKey: 'object_type',
    },
    {
        label: self.$t('column.locationCode'),
        key: 'location_code',
        sortable: true,
        sortKey: 'location_code',
    },
    {
        label: self.$t('column.location'),
        key: 'location_id',
        sortable: true,
        sortKey: 'location_id',
    },
    {
        label: self.$t('column.createdAt'),
        class: 'text-right',
        key: 'created_at',
        sortable: true,
        sortKey: 'created_at',
    },
];

export default {
    mixins: [ListingMixin],
    components: {
        Datepicker,
        Treeselect
    },
    data() {
        return {
            operationTitle: 'title.locationCodes',
            filters: {...FILTER_STATE},
            listUrl: 'location/codes',
            columns: COLUMN_DEFINITION(this),
            show: true,
            dropdowns: {
                suppliers: [],
                dealers: [],
                manufacturers: [],
                clients: [],
            }
        }
    },
    mounted() {
        this.$title = this.$t('topBar.navigations.modules.locationCodes')
        this.getSuppliers();
        this.getManufacturers();
        this.getDealers();
        this.getClients();
    },
    methods: {
        hasListAccess() {
            return this.$global.hasPermission('locationcodesview')
        },
        getExtraParams() {
            return {
                filters: {
                    ...this.filters,
                    from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                    to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                },
            }
        },
        handleResetFilterClick() {
            this.filters = {...FILTER_STATE}
            this.isFilterApplied = 'reset'
            this.handleResetClick()
            this.loadList(this.listQueryParams)
        },
        async getSuppliers() {
            try {
                const response = await request({
                    url: '/dropdowns/suppliers',
                    method: "post"
                })

                const {data} = response
                this.dropdowns.suppliers = data

            } catch (e) {
                this.dropdowns.suppliers = []
            }
        },
        async getClients() {
            try {
                const response = await request({
                    url: '/dropdowns/clients',
                    method: "post"
                })

                const {data} = response
                this.dropdowns.clients = data
            } catch (e) {
                this.dropdowns.clients = []
            }
        },
        async getDealers() {
            try {
                const response = await request({
                    url: '/dropdowns/dealers',
                    method: "post"
                })

                const {data} = response
                this.dropdowns.dealers = data

            } catch (e) {
                this.dropdowns.dealers = []
            }
        },
        async getManufacturers() {
            try {
                const response = await request({
                    url: '/dropdowns/manufacturers',
                    method: "post"
                })

                const {data} = response
                this.dropdowns.manufacturers = data

            } catch (e) {
                this.dropdowns.manufacturers = []
            }
        },

    },
    computed: {
        ...mapState([
            'global',
            'settings'
        ]),
    },
}
</script>
